import ABI_HISTORY_V1_ERC721 from "./abis/HistoryERC721.json"
import IERC721 from "./common/IERC721.js"
import * as wallet from "./common/wallet.js"
import * as web3util from "./common/web3util.js"

const errorPrefix = 'HistoryV1ERC721 | ';

class HistoryV1ERC721 extends IERC721 {
    //
    async mintNewHistoryReport(contentHash, name, options={}){
        // Prepare input params
        let _domainId = options.domainId==undefined ? 0 : options.domainId;
        let _usingSSL = options.usingSSL==undefined ? true : options.usingSSL;
        let _value = options.value;
        if(_value == undefined){
            let conf = await this.getConfig();
            _value = conf._mintFee;
        }
        // Write to contract
        const res = await this.write(
            'mintNewHistoryReport',
            [_domainId, _usingSSL, name, contentHash],
            {
                value: _value,
                // gasLimit: 360000
            }
        )
        
        if (res) {
            return res.events
        }

        // Process event
        // const event = web3util.findEventArgs(res.events,"Transfer");
        // console.log("Call success with interested event: ", event);
        // if(event && event.tokenId){
        //     return event.tokenId;
        // }
        console.log("Failed to write with receipt: ", res);
        throw Error(errorPrefix + "Failed to write.")
    }

    async getConfig() {
        const conf = await this.read('getConfig')
        return conf;
    }

    async ownerOf(tokenId){
        const conf = await this.read('ownerOf',[tokenId])
        return conf;
    }

    async tokenEventId(tokenId){
        const conf = await this.read('tokenEventId',[tokenId])
        return conf;
    }

    async eventData(eventId){
        const conf = await this.read('eventData',[eventId])
        return conf;
    }
    async balanceOf(owner){
        const conf = await this.read('balanceOf',[owner])
        return conf;
    }
    async tokenOfOwnerByIndex(owner,index){
        const conf = await this.read('tokenOfOwnerByIndex',[owner,index])
        return conf;
    }

    async followHistoryEvent(eventId, options={}){
        let _value = options.value;
        if(_value == undefined){
            let conf = await this.getConfig();
            _value = conf._mintFee;
        }
        // Write to contract
        const res = await this.write(
            'followHistoryEvent',
            [eventId],
            {
                value: _value,
                gasLimit: 360000
            }
        )

        // Process event
        const event = web3util.findEventArgs(res.events,"Transfer");
        console.log("Call success with interested event: ", event);
        if(event && event.tokenId){
            return event.tokenId;
        }
        console.log("Failed to write with receipt: ", res);
        throw Error(errorPrefix + "Failed to write.")
    }

}

export default HistoryV1ERC721

export function createHistoryV1ERC721() {
    const chainId = wallet.getChainId();
    const adr = web3util.getContractAdr('HistoryERC721', chainId)
    return new HistoryV1ERC721(ABI_HISTORY_V1_ERC721, adr);
}
