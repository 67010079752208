import * as wallet from "./wallet.js"
import { address, chainName } from "../web3Config.js"

const errorConfig = 'Web3Config | ';

export function defaultOwnerIfNotSet(owner=null) {
    if(owner == null){
        return wallet.getUserAddress();
    }
    return owner;
}

export async function getContractAdr(contractName){
    let _chainId = await wallet.getChainId();
    return getContractAdr2(contractName, _chainId);
}

export function getContractAdr2(contractName, chainId) {
    let name = getChainName2(chainId);
    let adr = address[name][contractName];
    if(adr == undefined){
        throw Error(errorConfig + "Contract not found in config of: " + name);
    }
    return adr;
}

export function getChainName2(chainId) {
    let name = chainName[chainId];
    if(name == undefined){
        throw Error(errorConfig + "Chain not found in config.")
    }
    return name;
}

export async function getChainName() {
    let _chainId = await wallet.getChainId();
    return getChainName2(_chainId);
}

export function findEventArgs(events, eventName) {
    for (const event of events) {
        if(event.event == eventName){
            return event.args;
        }
    }
    return null;
}
