import ABI_IERC721 from "../abis/IERC721.json"
import BaseContract from "./BaseContract.js"
import * as web3util from "./web3util.js"

const errorPrefix = 'IERC721 | ';

class IERC721 extends BaseContract{
    async approveAll(spender,_approved) {
        const res = await this.write(
            'setApprovalForAll',
            [spender, _approved],
            {
                gasLimit:56096
            }
        )
        const event = web3util.findEventArgs(res.events,"ApprovalForAll");
        console.log("Call success with interested event: ", event);
        if(event && event.approved){
            return true;
        }
        console.log("Failed to approve with receipt: ", res);
        throw Error(errorPrefix + "Failed to approve.");
    }

    async ownerOf(tokenId){
        const conf = await this.read('ownerOf',[tokenId])
        return conf;
    }


    async isApprovedForAll(spender, owner=null) {
        let _owner = web3util.defaultOwnerIfNotSet(owner);
        const isApproved = await this.read(
            'isApprovedForAll',
            [_owner, spender]
        )
        return isApproved;
    }
    async getTokenURI(tokenId){
        const uri = await this.read('tokenURI', [tokenId]);
        return uri;
    }

    async safeTransferFrom(from, to, tokenId){
        // Write to contract
        const res = await this.write(
            'transferFrom',
            [from, to, tokenId],
            {
                gasLimit: 360000
            }
        )

        // Process event
        const event = web3util.findEventArgs(res.events,"Transfer");
        console.log("Call success with interested event: ", event);
        if(event && event.tokenId){
            return event.tokenId;
        }
        console.log("Failed to write with receipt: ", res);
        throw Error(errorPrefix + "Failed to write.")
    }
}

export default IERC721

export function createIErc721(address) {
    return new IERC721(ABI_IERC721, address);
}
