import ABI_HISTORY_V1_ERC721 from "./abis/minter721s.json"
import IERC721 from "./common/IERC721.js"
import * as wallet from "./common/wallet.js"
import * as web3util from "./common/web3util.js"


class HistoryNewMarket721s extends IERC721 {
    async seriesInfo(){
        const conf = await this.read('seriesInfo')
        return conf;
    }
}
export default HistoryNewMarket721s

export function createMinter721s(tokenaddress) {
    const chainId = wallet.getChainId();
    web3util.getContractAdr("HistoryMarket_address",chainId)
    return new HistoryNewMarket721s(ABI_HISTORY_V1_ERC721, tokenaddress);
}