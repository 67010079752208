import ABI_HistoryMarket from "./abis/HistoryMarket.json"
import IERC721 from "./common/IERC721.js"
import * as wallet from "./common/wallet.js"
import * as web3util from "./common/web3util.js"

const errorPrefix = 'HistoryMarket | ';
class HistoryMarket extends IERC721 {
    async tokenSaleInfo(contractAdr, tokenId) {
        const conf = await this.read('tokenSaleInfo', [contractAdr, tokenId])
        return conf;
    }

    async sellNft(token, tokenId, price) {
        // Write to contract
        const res = await this.write(
            'sellNft',
            [token, tokenId, price], {
                // value: _value._mintFee,
                // gasLimit: 360000
            }
        )

        // Process event
        const event = web3util.findEventArgs(res.events, "SaleInfoChangedEvt");
        console.log("Call success with interested event: ", event);
        if (event && event.tokenId) {
            return event.tokenId;
        }
        console.log("Failed to write with receipt: ", res);
        throw Error(errorPrefix + "Failed to write.")
    }

    async buyNft(token, tokenId, to, _value) {
        // Write to contract
        const res = await this.write(
            'buyNft',
            [token, tokenId, to], {
                value: _value,
                // gasLimit: 360000
            }
        )

        return res

        // Process event
        // const event = web3util.findEventArgs(res.events,"NFTSoldEvt");
        // console.log("Call success with interested event: ", event);
        // if(event && event.tokenId){
        //     return event.tokenId;
        // }
        // console.log("Failed to write with receipt: ", res);
        // throw Error(errorPrefix + "Failed to write.")
    }

}

export default HistoryMarket

export function queryHistoryMarket() {
    const chainId = wallet.getChainId();
    const adr = web3util.getContractAdr('HistoryMarket_address', chainId)
    return new HistoryMarket(ABI_HistoryMarket, adr);
}